
import * as ipxRuntime$l6QfBz4Vyb from '/home/node/app/node_modules/.pnpm/@nuxt+image@1.4.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1400,
    "2xl": 1536,
    "xxxl": 1800
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "localhost:8000",
    "api.bebeauty.site",
    "api.glow.day"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$l6QfBz4Vyb, defaults: {} }
}
        